var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "nav" }, [
      _vm._m(0),
      _c("div", { staticClass: "menu" }, [
        _vm._m(1),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticClass: "lang",
                staticStyle: { width: "90px" },
                attrs: { placeholder: "Please enter", size: "mini" },
                on: { change: _vm.changeLang },
                model: {
                  value: _vm.langVal,
                  callback: function($$v) {
                    _vm.langVal = $$v
                  },
                  expression: "langVal"
                }
              },
              _vm._l(_vm.lang, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              }),
              1
            )
          ],
          1
        ),
        _c("div", { attrs: { id: "menuBtn" }, on: { click: _vm.nav } }, [
          _vm._m(2)
        ])
      ])
    ]),
    _vm._m(3),
    _vm._m(4)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("div", { staticClass: "logo1" }, [
        _c("a", { attrs: { href: "javascript:;" } }, [
          _c("img", { attrs: { src: "/img/home/cnlogo1.png" } })
        ])
      ]),
      _c("div", { staticClass: "logo2" }, [
        _c("a", [_c("img", { attrs: { src: "/img/home/cnlogo2.png" } })])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menuList", attrs: { id: "menuList" } }, [
      _c("a", { staticClass: "act", attrs: { href: "javascript:;" } }, [
        _vm._v("Home")
      ]),
      _c("a", { attrs: { href: "javascript:;" } }, [
        _vm._v("Application Effect")
      ]),
      _c("a", { attrs: { href: "javascript:;" } }, [
        _vm._v("Technical Advantage")
      ]),
      _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("Partner")]),
      _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("Login")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", [_c("span"), _c("span"), _c("span")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "bannerImg" }, [
        _c("img", { attrs: { src: "/img/home/banner.png" } })
      ]),
      _c("div", { staticClass: "bannerCon" }, [
        _c("h2", { attrs: { id: "bigTit" } }, [
          _vm._v("纺织行业数字化转型服务商")
        ]),
        _c("h4", { attrs: { id: "smallTit" } }, [
          _vm._v("设备升级改造、SAAS软件、实施、维保、咨询、技术支持一条龙")
        ]),
        _c("div", { staticClass: "list" }, [
          _c("div", [
            _c("div", [_c("img", { attrs: { src: "/img/home/mesIcon.png" } })]),
            _c("h4", [_vm._v("MES")]),
            _c("p", [
              _vm._v(
                "生产物联管理、明晰人员、设备、物料、能耗、产能、工艺、质量、排班、绩效等"
              )
            ])
          ]),
          _c("div", [
            _c("div", [_c("img", { attrs: { src: "/img/home/jxcIcon.png" } })]),
            _c("h4", [_vm._v("进销存")]),
            _c("p", [
              _vm._v("原料采购、销售合同、出入库、配件采购和管理、产品追溯等")
            ])
          ]),
          _c("div", [
            _c("div", [_c("img", { attrs: { src: "/img/home/aiIcon.png" } })]),
            _c("h4", [_vm._v("AI辅助")]),
            _c("p", [
              _vm._v(
                "生产物联管理、明晰人员、设备、物料、能耗、产能、工艺、质量、排班、绩效等"
              )
            ])
          ]),
          _c("div", [
            _c("div", [_c("img", { attrs: { src: "/img/home/kpIcon.png" } })]),
            _c("h4", [_vm._v("跨屏支持")]),
            _c("p", [_vm._v("支持联屏看板、Pad、手机、嵌入式系统的显示及操作")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "num" }, [
      _c("div", [
        _c("div", [_c("h4", [_vm._v("2000+")]), _c("p", [_vm._v("工厂")])]),
        _c("div", [_c("h4", [_vm._v("5000+")]), _c("p", [_vm._v("设备")])]),
        _c("div", [
          _c("h4", [_vm._v("2,000,000")]),
          _c("p", [_vm._v("数据条数")])
        ]),
        _c("div", [
          _c("h4", [_vm._v("100m/day")]),
          _c("p", [_vm._v("一天记录的数据")])
        ]),
        _c("div", [_c("h4", [_vm._v("500,000")]), _c("p", [_vm._v("报警数")])])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }