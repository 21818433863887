var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogTableVisible,
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTableVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { padding: "0 1.875rem 0 1.25rem !important" },
              attrs: { data: _vm.gridData },
              on: { "row-click": _vm.rowClick }
            },
            [
              _c("el-table-column", {
                attrs: { width: "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row.id },
                            model: {
                              value: _vm.radio,
                              callback: function($$v) {
                                _vm.radio = $$v
                              },
                              expression: "radio"
                            }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s("") +
                                "\n                    "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "name", label: _vm.$t("COMPANY") }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.goHome } },
                [_vm._v(_vm._s(_vm.$t("Enter")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.goLogOut } },
                [_vm._v(_vm._s(_vm.$t("Log Out")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.noCompany,
            width: "30%",
            "append-to-body": "",
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false
          },
          on: {
            "update:visible": function($event) {
              _vm.noCompany = $event
            }
          }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.$t("Entertip")))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.goLogOut } },
                [_vm._v(_vm._s(_vm.$t("Log Out")))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }