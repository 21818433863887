<template>
    <div >
        <el-dialog :visible.sync="dialogTableVisible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <el-table :data="gridData" @row-click="rowClick" style="padding: 0 1.875rem 0 1.25rem !important; ">
                <el-table-column width="30">
                    <template slot-scope="scope">
                        <el-radio v-model="radio" :label="scope.row.id">
                            {{
                     ""
                            }}
                        </el-radio>
                    </template>
                </el-table-column>
                <el-table-column property="name" :label="$t('COMPANY')" ></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" @click="goHome">{{$t('Enter')}}</el-button>
              
              <!-- <el-button type="primary">注册/加入新公司</el-button> -->
                <el-button type="danger" @click="goLogOut">{{$t('Log Out')}}</el-button>              
            </span>
        </el-dialog>

        <el-dialog :visible.sync="noCompany"
               width="30%"
               append-to-body :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <span>{{$t('Entertip')}}</span>
            <span slot="footer" class="dialog-footer" >
                <!-- <el-button type="primary">注册/加入公司</el-button> -->
                <el-button type="danger" @click="goLogOut">{{$t('Log Out')}}</el-button>
            </span>
      </el-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Cookies from "js-cookie";

    export default {
        name: "userlogin",
        data() {
            return {
                radio: "",
                gridData: [],
                dialogTableVisible: false,
                checked: true,
                noCompany: false
            };
        },
        created() {
            this.getCom();
        },
        mounted() { },
        computed: {
            ...mapGetters(["tagWel", 'tagList']),
        },
        props: [],
        methods: {
            ...mapActions('oidcStore', [
                'authenticateOidcPopup',
                'removeOidcUser',
                'signOutOidc'
            ]),
            rowClick(row) {
                this.radio = row.id;
            },
            goHome() {
                //将地址栏项的数据清空掉
                if (this.tagList != null || this.tagList != undefined) {
                    for (var j = this.tagList.length - 1; j > -1; j--) {
                        this.tagList.splice(j, 1);
                    }
                }
                if (!this.radio) {
                    this.$message({
                        type: "error",
                        message: this.$t('Please select a company'),
                    });
                    return;
                } else {
                    const grop = "gropId";
                    Cookies.set(grop, this.radio);

                    this.$router.push({ path: this.tagWel.value });
                }
            },
            getCom() {
                //let access_token = getToken()
                let res = this.request.post(
                    this.businessUrl + "corporation/queryCorp"
                );
                res.then(v => {
                    //console.log(v, 'pl')
                    v.data.data != null ? (this.gridData = v.data.data) : "";
                    //console.log(this.gridData);
                    if (this.gridData.length < 1) {
                        // this.$message({
                        //     type: "error",
                        //     message: "用户无公司",
                        // });
                        this.noCompany = true;
                        return;
                    } else if (this.gridData.length == 1) {
                        this.radio = this.gridData[0].id;
                        this.goHome();
                    }
                    else {
                        this.dialogTableVisible = true;
                    }
                })
            },
            goLogOut() {
                var gropInfo = Cookies.get("gropId");
                //清空公司信息
                if (gropInfo != null || gropInfo != undefined)
                {
                    Cookies.remove("gropId")
                }
               this.signOutOidc();
            },
        }
    };
</script>

<style scoped>
.loginToForm {
  display: flex;
  margin: 20px auto;
}
    .el-dialog {
        border-radius: 30px;
    }

.btn-box {
  display: flex;
  justify-content: center;
}

</style>
