<template>
  <div class="login-container pull-height" @keyup.enter.native="handleLogin">
    <div>
        <el-dialog :visible.sync="needLogout"
               width="30%" :title="$t('Tips')"
               append-to-body :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <span>{{$t('Exit the system, continue')}}</span>
            <span slot="footer" class="dialog-footer" >
                <el-button @click="goHome">{{$t('Cancel')}}</el-button>
                <el-button type="danger" @click="goLogOut">{{$t('Log Out')}}</el-button>
            </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Cookies from "js-cookie";
import topColor from "../index/top/top-color";
import color from "@/mixins/color";
export default {
  name: "logout",
  mixins: [color()],
  components: {
    topColor
  },
  data () {
    return {
      activeName: "user",
      needLogout: true
    };
  },
  watch: {
    $route () { }
  },
  created () { },
  mounted () { },
  computed: {
    ...mapGetters(["website","tagWel"])
  },
  props: [],
        methods: {
            ...mapActions('oidcStore', [
                'authenticateOidcPopup',
                'removeOidcUser',
                'signOutOidc'
            ]),
            goHome() {
                this.$router.push({ path: this.tagWel.value });
            },
            goLogOut() {
                var gropInfo = Cookies.get("gropId");
                // 退出登录清空公司ID
                if (gropInfo != null || gropInfo != undefined) {
                    Cookies.remove("gropId")
                }
                this.signOutOidc();
            },
        }
};
</script>

<style lang="scss">
.loginToForm {
  display: flex;
  margin: 20px auto;
}
    .el-dialog {
        border-radius: 30px;
    }

.btn-box {
  display: flex;
  justify-content: center;
}
.login-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.4);
}
.login-weaper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 500px;
  margin-top: -200px;
}
.login-container::before {
    z-index: -1024;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("/img/login.png");
    background-size: cover;    
}
.login-left {
  margin-top: -30px;
  float: left;
  width: 40%;
  position: relative;
}
.login-logo {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  font-size: 24px;
  color: #333;
}
.login-info {
  padding-left: 140px;
}
.login-info-title {
  line-height: 200px;
  color: rgb(52, 58, 64);
}
.login-info-item {
  font-size: 14px;
  line-height: 2.5;
  color: rgb(33, 37, 41);
}
.login-border {
  margin-top: 30px;
  width: 60%;
  float: left;
  box-sizing: border-box;
}
.login-main {
  margin: 0 auto;
  width: 50%;
  box-sizing: border-box;
}
.login-main > h3 {
  margin-bottom: 20px;
}
.login-main > p {
  color: #76838f;
}
.login-title {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  letter-spacing: 4px;
}
.login-menu {
  width: 100%;
  text-align: center;
  a {
    color: rgb(153, 153, 153);
    font-size: 12px;
    margin: 0px 8px;
  }
}
.login-index {
  margin-top: 40px !important;
  width: 180px;
  height: 48px;
  text-align: center;
  border-radius: 50px !important;
  background: transparent !important;
  color: #333 !important;
  font-size: 16px !important;
  border: 1px solid rgb(152, 22, 244);
}
.login-submit {
  display: block !important;
  margin: 40px auto 10px auto !important;
  width: 200px;
  height: 48px;
  font-size: 14px !important;
  text-align: center;
  border-radius: 50px !important;
  border: 0px;
  box-shadow: rgba(152, 22, 244, 0.19) 0px 5px 10px 2px;
}
.login-form {
  margin: 10px 0;
  i {
    color: #999;
  }
  .el-form-item__content {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 12px;
  }
  .el-input {
    input {
      padding-bottom: 10px;
      text-indent: 5px;
      background: transparent;
      border: none;
      border-radius: 0;
      color: #333;
      border-bottom: 1px solid rgb(235, 237, 242);
    }
    .el-input__prefix {
      i {
        padding: 0 5px;
        font-size: 16px !important;
      }
    }
  }
}
.login-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 10px;
}
.login-code-img {
  margin-top: 2px;
  width: 100px;
  height: 38px;
  background-color: #fdfdfd;
  border: 1px solid #f0f0f0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 38px;
  text-indent: 5px;
  text-align: center;
}
</style>