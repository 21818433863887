<template>
    <div class="home">
        <div class="nav" id="nav">
            <div class="logo">
                <div class="logo1">
                    <a href="javascript:;">
                        <img src="/img/home/cnlogo1.png" />
                    </a>
                </div>
                <div class="logo2">
                    <a>
                        <img src="/img/home/cnlogo2.png" />
                    </a>
                </div>
            </div>
            <div class="menu">
                <div class="menuList" id="menuList">
                    <a href="javascript:;" class="act">首页</a>
                    <a href="javascript:;">应用效果</a>
                    <a href="javascript:;">技术优势</a>
                    <a href="javascript:;">合作伙伴</a>
                    <a href="javascript:;">登录</a>
                </div>
                <!--<div>
                    <el-select v-model="langVal" placeholder="请选择" size="mini" class="lang" style="width:80px;" @change="changeLang">
                        <el-option v-for="item in lang"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </div>-->
                <div id="menuBtn" @click="nav">
                    <button>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="banner" id="banner">
            <div class="bannerImg">
                <img src="/img/home/banner.png" />
            </div>
            <div class="bannerCon">
                <h2 id="bigTit">纺织行业数字化转型服务商</h2>
                <h4 id="smallTit">设备升级改造、SAAS软件、实施、维保、咨询、技术支持一条龙</h4>
                <div class="list">
                    <div class="topDiv">
                        <div>
                            <img src="/img/home/mesIcon.png" />
                        </div>
                        <h4>MES</h4>
                        <p>生产物联管理、明晰人员、设备、物料、能耗、产能、工艺、质量、排班、绩效等</p>
                    </div>
                    <div class="topDiv">
                        <div>
                            <img src="/img/home/jxcIcon.png" />
                        </div>
                        <h4>进销存</h4>
                        <p>原料采购、销售合同、出入库、配件采购和管理、产品追溯等</p>
                    </div>
                    <div class="topDiv">
                        <div>
                            <img src="/img/home/aiIcon.png" />
                        </div>
                        <h4>AI辅助</h4>
                        <p>智能预警、BI分析、在线客服智能答疑，知识库管理</p>
                    </div>
                    <div class="topDiv">
                        <div>
                            <img src="/img/home/kpIcon.png" />
                        </div>
                        <h4>跨屏支持</h4>
                        <p>支持联屏看板、Pad、手机、嵌入式系统的显示及操作</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="num" id="num">
            <div>
                <div>
                    <h4><span id="gcnum">2000</span>+</h4>
                    <p>工厂</p>
                </div>
                <div>
                    <h4><span id="sbnum">5000</span>+</h4>
                    <p>设备</p>
                </div>
                <div>
                    <h4 id="sjnum">2,000,000</h4>
                    <p>数据条数</p>
                </div>
                <div>
                    <h4><span id="ytnum">100</span>m/day</h4>
                    <p>一天记录的数据</p>
                </div>
                <div>
                    <h4 id="bjnum">500,000</h4>
                    <p>报警数</p>
                </div>
            </div>
        </div>
        <div class="moblist" id="moblist">
            <div class="mob1">
                <img src="/img/home/mobicon1.png" />
                <div>
                    <h4>MES</h4>
                    <p>生产物联管理、明晰人员、设备、物料、能耗、产能、工艺、质量、排班、绩效等</p>
                </div>
            </div>
            <div class="mob2">
                <img src="/img/home/mobicon2.png" />
                <div>
                    <h4>进销存</h4>
                    <p>原料采购、销售合同、出入库、配件采购和管理、产品追溯等</p>
                </div>
            </div>
            <div class="mob3">
                <img src="/img/home/mobicon3.png" />
                <div>
                    <h4>AI辅助</h4>
                    <p>智能预警、BI分析、在线客服智能答疑，知识库管理</p>
                </div>
            </div>
            <div class="mob4">
                <img src="/img/home/mobicon4.png" />
                <div>
                    <h4>跨屏支持</h4>
                    <p>支持联屏看板、Pad、手机、嵌入式系统的显示及操作</p>
                </div>
            </div>
        </div>
        <div class="application" id="application">
            <div class="appTit">
                <h4>应用效果</h4>
                <p>APPLICATION</p>
            </div>
            <div class="appList">
                <div class="app1" style="transform:translateX(-200%) translateY(-200%)">
                    <img src="/img/home/app1.png" />
                    <div>
                        <div>
                            <h5>产能</h5>
                            <p>提升开台率、减少意外停机</p>
                        </div>
                    </div>
                </div>
                <div class="app2" style="transform:translateX(200%) translateY(-200%)">
                    <img src="/img/home/app2.png" />
                    <div>
                        <div>
                            <h5>效益</h5>
                            <p>降低工人数、提高单人产量</p>
                        </div>
                    </div>
                </div>
                <div class="app3" style="transform:translateX(-200%) translateY(200%)">
                    <img src="/img/home/app3.png" />
                    <div>
                        <div>
                            <h5>管理</h5>
                            <p>自动计件、质量监控</p>
                        </div>
                    </div>
                </div>
                <div class="app4" style="transform:translateX(200%) translateY(200%)">
                    <img src="/img/home/app4.png" />
                    <div>
                        <div>
                            <h5>追溯</h5>
                            <p>从原棉到成衣，全链条追溯</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="function" id="function">
            <div class="appTit">
                <h4>技术优势</h4>
                <p>FUNCTION</p>
            </div>
            <div class="funcList">
                <div class="func1" style="transform: translateX(200%);">
                    <span>1</span>
                    <div>
                        <h5>智能物联AIoT</h5>
                        <p>软硬结合，优化边缘计算提升效果和效率</p>
                    </div>
                </div>
                <div class="func2" style="transform: translateX(200%);">
                    <span>2</span>
                    <div>
                        <h5>实时大数据</h5>
                        <p>秒级全车间单锭数据处理能力，瞬时通达</p>
                    </div>
                </div>
                <div class="func3" style="transform: translateX(200%);">
                    <span>3</span>
                    <div>
                        <h5>全流程追溯</h5>
                        <p>从原棉、纺纱、织染到成衣，全流程追溯</p>
                    </div>
                </div>
                <div class="func4" style="transform: translateX(200%);">
                    <span>4</span>
                    <div>
                        <h5>开放平台</h5>
                        <p>通过开放式工业级数据接口，与主流ERP系统对接</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="client" id="client">
            <div class="appTit">
                <h4>合作伙伴</h4>
                <p>CLIENT</p>
            </div>
            <div class="clientList">
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
                <div class="oj" style="transform: scale(0);">123</div>
            </div>
        </div>
        <div class="footer">
            <div>
                Copyright ® 2017-2021 General Intelligence All rights reserved.<br />
                通用智能有限公司（香港）| 玑脉（上海）数据技术有限公司<br />
                沪ICP备17047921号-2
            </div>
        </div>
    </div>
</template>

<style lang="less">
    .home * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased !important;
    }
    .nav {
        width: 100%;
        height: 80px;
        background: rgba(0,0,0,.3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        position: fixed;
        left: 0;
        top: 0;
        z-index:2;
        transition:all .5s;
    }
    .logo {
        display: flex;
        align-items: center;
    }
    .logo1 {
        padding-right: 25px;
        border-right: 2px solid rgba(255,255,255,.8);
        margin-right: 25px;
    }
    .menu {
        display: flex;
        align-items: center;
    }
    .menuList {
        display: block;
        transition:all .5s;
    }
    .menuList a {
        font-size: 22px;
        color: #DBDAD5;
        padding: 0 20px;
        font-weight: 500;
        transition: all .5s;
    }
    .menuList a:hover, .menuList a.act {
        color: #fff;
        text-decoration-line: none;
        border-bottom: 2px solid #fff;
        padding-bottom: 10px;
    }
    #menuBtn button {
        padding: 10px 12px;
        border: 1px solid rgba(255,255,255,.5);
        border-radius: 3px;
        background: transparent;
        width: 50px;
        height: 40px;
        cursor: pointer;
    }
    #menuBtn span {
        width: 100%;
        height: 1px;
        background: rgba(255,255,255,.5);
        margin-bottom: 8px;
        display: block;
    }
    #menuBtn {
        display: none;
    }
    .banner{
        position:relative;
    }
    .bannerImg {
        width: 100%;
    }
    .bannerImg img{
        width:100%;
        margin:0;
    }
    .bannerCon{
        position:absolute;
        left:0;
        top:0;
        padding-top:10%;
        height:100%;
        z-index:1;
        width:100%;
    }
    #bigTit {
        font-size: 64px;
        text-align: center;
        color: #fff;
        margin-bottom: 60px;
        transform: translateX(-100%);
    }
    #smallTit {
        font-size: 40px;
        text-align: center;
        color: #fff;
        transform: translateX(-100%);
    }
    .list{
        max-width:1260px;
        margin:0 auto;
        display:flex;
        justify-content:space-between;
        margin-top:80px;
    }
    .list > div {
        margin-right: 23px;
        border: 1px solid rgba(255,255,255,.8);
        border-radius: 8px;
        background: rgba(255,255,255,.2);
        padding: 50px;
        width: calc(25% - 17.25px);
        transform: translateY(-500%);
    }
    .list > div:last-child {
        margin-right: 0;
    }
    .list > div>div{
        text-align:center;
    }
    .list > div img {
        width: 88px;
    }
    .list > div h4{
        font-size:24px;
        color:#fff;
        margin:20px 0;
        text-align:center;
    }
    .list > div p {
        font-size: 16px;
        color: #fff;
        line-height:26px;
    }
    .num {
        background: #F9F9F9;
    }
    .num>div{
        max-width:1260px;
        margin:0 auto;
        display:flex;
        align-items:center;
        justify-content:space-between;
        min-height:500px;
        text-align:center;
    }
    .num > div>div{
        width:20%;
    }
    .num > div > div>h4{
        font-size:44px;
        color:#333;
        font-weight:700;
        margin-bottom:40px;
    }
    .num > div > div>p{
        font-size:28px;
        color:#333;
    }
    .lang .el-input__inner{
        background:transparent;
        color:#fff;
    }
    .application{
        padding:75px 50px 35px;
    }
    .appTit {
        text-align: center;
        margin-bottom:45px;
    }
    .appTit h4{
        font-size:32px;
        color:#333;
    }
    .appTit p {
        font-size: 20px;
        color: #999;
    }
    .appList{
        display:flex;
        justify-content:space-between;
        flex-wrap:wrap;
        max-width:1260px;
        margin:0 auto;
    }
    .appList > div {
        width: calc(50% - 20px);
        margin-bottom: 40px;
        position: relative;
        cursor:pointer;
    }
    .appList > div:nth-child(odd){
        margin-right:40px;
    }
    .appList > div>img{
        width:100%;
    }
    .appList > div > div {
        position: absolute;
        left: 0;
        bottom: 4px;
        background: rgba(0,0,0,.5);
        padding: 20px;
        height:120px;
        transition: height .8s;
        transition-timing-function: ease;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width:100%;
    }
    .appList > div:hover > div {
        height: calc(100% - 4px);
    }
    .appList > div:hover > div p {
        display: block;
    }
    .appList > div > div h5 {
        font-size: 24px;
        color: #ccc;
        margin-bottom: 15px;
        width: 100%;
    }
    .appList > div > div p {
        font-size: 16px;
        color: #ccc;
        line-height: 26px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .function{
        padding:75px 50px;
        background:url('/img/home/func.png') center no-repeat;
        background-size:cover;
        height:600px;
    }
    .function .appTit{
        margin-bottom:20px;
    }
    .function h4 {
        color: #fff;
    }
    .funcList{
        max-width:1260px;
        margin:0 auto;
        position:relative;
    }
    .funcList > div {
        position: absolute;
        right: 40px;
        display:flex;
        align-items:center;
        transition:all .5s ease;
        cursor:pointer;
    }
    .funcList > div:nth-child(1){
        top:0;
        right:130px;
    }
    .funcList > div:nth-child(2) {
        top: 110px;
        right:80px;
    }
    .funcList > div:nth-child(3) {
        top: 220px;
        right:100px;
    }
    .funcList > div:nth-child(4) {
        top: 330px;
        right:80px;
    }
    .funcList > div span{
        display:inline-block;
        border:2px solid #999;
        transition:all .5s ease;
        border-radius:50%;
        color:#999;
        margin-right:20px;
    }
    .funcList > div:nth-child(1) span {
        width:70px;
        height:70px;
        text-align:center;
        line-height:70px;
        font-size:40px;
    }
    .funcList > div:nth-child(2) span {
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        font-size:30px;
    }
    .funcList > div:nth-child(3) span {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size:25px;
    }
    .funcList > div:nth-child(4) span {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size:25px;
    }
    .funcList > div h5{
        font-size:24px;
        margin-bottom:10px;
        color:#999;
    }
    .funcList > div p{
        font-size:18px;
        line-height:26px;
        color:#999;
    }
    .funcList > div:hover span {
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 80px;
        border-color:#fff;
        font-size:50px;
        color:#fff;
    }
    .funcList > div:hover h5 {
        color:#fff;
    }
    .funcList > div:hover p {
        color: #fff;
    }
    .client{
        padding:75px 50px;
    }
    .clientList{
        max-width:1260px;
        margin:0 auto;
        display:flex;
        align-items:center;
        flex-wrap:wrap;
    }
    .clientList>div{
        width:calc(16.66% - 25px);
        margin-right:30px;
        margin-bottom:30px;
        height:85px;
        background:#aaa;
    }
    .clientList > div:nth-child(6n){
        margin-right:0;
    }
    .footer{
        width:100%;
        height:500px;
        background:url('/img/home/footer.png') center no-repeat;
        background-size:cover;
        text-align:center;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size:20px;
        color:#999;
        line-height:32px;
    }
    .moblist{
        display:none;
    }
    @media(max-width:1400px) {
        #bigTit {
            font-size: 40px;
            margin-bottom: 30px;
        }
        #smallTit {
            font-size: 26px;
        }
        .list{
            margin-top:30px;
            padding:0 30px;
        }
        .list > div{
            padding:20px;
        }
        .list > div img {
            width: 68px;
        }
        .num > div{
            min-height:400px;
        }
        .num > div > div > h4{
            font-size:34px;
        }
        .num > div > div > p {
            font-size: 22px;
        }
        .appList > div > div{
            height:100px;
        }
        .appList > div > div h5{
            font-size:20px;
            margin-bottom:10px;
        }
        .appList > div > div p{
            font-size:14px;
        }
    }
    @media(max-width:992px) {
        .menuList a {
            font-size: 18px;
            padding:10px 12px !important;
        }
        #bigTit {
            font-size: 28px;
            margin-bottom: 20px;
        }
        #smallTit {
            font-size: 18px;
        }
        .list {
            margin-top: 20px;
            padding: 0 30px;
        }
        .list > div {
            padding: 10px;
            transform:translateY(-100%);
        }
        .list > div img {
            width:40px;
        }
        .list > div h4{
            font-size:18px;
            margin:10px 0;
        }
        .list > div p {
            font-size:14px;
            line-height:20px;
        }
        .bannerCon{
            padding-top:13%;
        }
        .logo1{
            width:155px;
            padding-right:10px;
            margin-right:10px;
        }
        .logo2{
            width:80px;
        }
        .logo1 img,.logo2 img{
            width:100%;
        }
        .menuList {
            display: block;
        }
        .nav{
            padding:0 10px;
        }
        .num > div{
            flex-wrap:wrap;
            justify-content:flex-start;
        }
        .num > div>div{
            width:33%;
        }
        .application{
            padding:30px 15px 10px;
        }
        .appTit{
            margin-bottom:20px;
        }
        .appList > div:nth-child(odd) {
            margin-right: 20px;
        }
        .appList > div{
            margin-bottom:20px;
            width:calc(50% - 10px);
        }
        .client {
            padding: 30px 15px 10px;
        }
        .clientList > div {
            width: calc(25% - 15px);
            margin-right: 20px;
            margin-bottom: 20px;
        }
        .clientList > div:nth-child(6n) {
            margin-right: 20px;
        }
        .clientList > div:nth-child(4n) {
            margin-right: 0;
        }
    }

    @media(max-width:767px) {
        .menuList {
            width: 100%;
            position: absolute;
            top: 64px !important;
            left: 0;
            background: rgba(0,0,0,.9) !important;
            padding-bottom: 0;
            height: 0;
            overflow: hidden;
        }
        .menuList a {
            width: 100%;
            padding: 10px 20px !important;
            display: block;
        }
        .menuList a:hover, .menuList a.act {
            background: rgba(255,255,255,.4);
            color: #333;
            border:none;
        }
        #menuBtn {
            display: block;
            margin-left:20px;
        }
        .appList > div:nth-child(odd) {
            margin-right: 0;
        }
        .appList > div {
            margin-bottom: 20px;
            width: 100%;
        }
        .client{
            padding:30px 15px 10px;
        }
        .clientList > div {
            width: calc(33.33% - 13.33px);
            margin-right: 20px;
            margin-bottom: 20px;
        }
        .clientList > div:nth-child(6n) {
            margin-right: 20px;
        }
        .clientList > div:nth-child(4n) {
            margin-right: 20px;
        }
        .clientList > div:nth-child(3n){
            margin-right:0;
        }
        .footer {
            font-size: 16px;
        }
        .footer>div{
            padding:0 20px;
        }
        .function{
            padding:30px 15px;
        }
        .funcList>div{
            width:100%;
            right:0 !important;
        }
        .funcList > div h5{
            font-size:26px;
        }
        .funcList > div p{
            font-size:16px;
        }
        .funcList > div:nth-child(1) span {
            width: 60px;
            height: 60px;
            text-align: center;
            line-height: 60px;
            font-size: 34px;
        }
        .funcList > div:nth-child(2) span {
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 28px;
        }
        .funcList > div:nth-child(3) span {
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 20px;
        }
        .funcList > div:nth-child(4) span {
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 20px;
        }
        .list{
            display:none !important;
        }
        .bannerCon{
            padding-top:23% !important;
        }
        #bigTit{
            font-size:24px !important;
        }
        #smallTit{
            font-size:14px !important;
            padding:0 15px !important;
        }
        .num > div > div{
            width:50% !important;
        }
        .num > div > div > h4{
            font-size:28px !important;
            margin-bottom:20px !important;
        }
        .num > div > div > p{
            font-size:18px !important;
        }
        .nav{
            height:64px !important;
        }
        .logo1{
            width:123px !important;
        }
        .logo2 {
            width: 64px !important;
        }
        .moblist{
            display:block;
            padding:30px 15px 15px;
        }
        .moblist>div{
            display:flex;
            align-items:center;
            box-shadow:0 0 3px 3px rgba(0,0,0,.05);
            padding:15px;
            background:#eee;
            margin-bottom:15px;
            transform:translateX(200%);
        }
        .moblist>div>img{
            width:40px;
            margin-right:20px;
        }
        .moblist>div h4{
            font-size:16px;
            color:#333;
            margin-bottom:10px;
        }
        .moblist>div p{
            font-size:14px;
            color:#333;
            line-height:20px;
        }
    }
</style>
<script>
export default {
    data () {
        return {
            langVal:"cn",
            lang: [
                {
                    value: 'cn',
                    label: '中文'
                },
                {
                    value: 'en',
                    label: 'English'
                }
            ]
        };
    },
    mounted() {
        window.onresize = function () {
            if (document.body.clientWidth >= 768) {
                document.querySelector("#menuList").style.cssText = "display:block";
            } else {
                document.querySelector("#menuList").style.cssText = "display:none";
            }
        }
        document.querySelector("#app").style.cssText = "height:auto;";
        
        let that = this;
        window.onload = function () {
            if (that.isElem(document.querySelector("#banner"))) {
                that.bannerAni();
            }
            if (that.isElem(document.querySelector("#num"))) {
                that.numAdd('gcnum', {
                    time: 5000,
                    finalNum: 2000,
                    regulator: 50
                })
                that.numAdd('sbnum', {
                    time: 5000,
                    finalNum: 5000,
                    regulator: 50
                })
                that.numAdd('sjnum', {
                    time: 5000,
                    finalNum: 20000000,
                    regulator: 50
                })
                that.numAdd('ytnum', {
                    time: 5000,
                    finalNum: 100,
                    regulator: 50
                })
                that.numAdd('bjnum', {
                    time: 5000,
                    finalNum: 500000,
                    regulator: 50
                })
            }
            if (that.isElem(document.querySelector("#moblist"))) {
                that.mobAni();
            }
            if (that.isElem(document.querySelector("#application"))) {
                that.appAni();
            }
            if (that.isElem(document.querySelector("#function"))) {
                that.funcAni();
            }
            if (that.isElem(document.querySelector("#client"))) {
                that.clentAni();
            }
        }

        window.onscroll = function () {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop > 0) {
                document.querySelector("#nav").style.background = "rgba(0,0,0,.8)";
            } else {
                document.querySelector("#nav").style.background = "rgba(0,0,0,.3)";
            }
            if (that.isElem(document.querySelector("#banner"))) {
                that.bannerAni();
            }
            if (that.isElem(document.querySelector("#num"))) {
                that.numAdd('gcnum', {
                    time: 5000,
                    finalNum: 2000,
                    regulator: 50
                })
                that.numAdd('sbnum', {
                    time: 5000,
                    finalNum: 5000,
                    regulator: 50
                })
                that.numAdd('sjnum', {
                    time: 5000,
                    finalNum: 20000000,
                    regulator: 50
                })
                that.numAdd('ytnum', {
                    time: 5000,
                    finalNum: 100,
                    regulator: 50
                })
                that.numAdd('bjnum', {
                    time: 5000,
                    finalNum: 500000,
                    regulator: 50
                })
            }
            if (that.isElem(document.querySelector("#moblist"))) {
                that.mobAni();
            }
            if (that.isElem(document.querySelector("#application"))) {
                that.appAni();
            }
            if (that.isElem(document.querySelector("#function"))) {
                that.funcAni();
            }
            if (that.isElem(document.querySelector("#client"))) {
                that.clentAni();
            }
        } 
    },
    created(){
        
    },
    destroyed() {
        document.querySelector("#app").style.cssText = "height:100%;";
    },
    methods: {
        nav() {
            var h = window.getComputedStyle(document.querySelector("#menuList")).height;
            if (h === "0px") {
                document.querySelector("#menuList").style.cssText = 'height:auto;padding-bottom:20px;';
            } else {
                document.querySelector("#menuList").style.cssText = 'height:0;padding-bottom:0;';
            }
        },
        setNum(num) {
            var result = [], counter = 0;
            num = (num || 0).toString().split('');
            for (var i = num.length - 1; i >= 0; i--) {
                counter++;
                result.unshift(num[i]);
                if (!(counter % 3) && i != 0) { result.unshift(','); }
            }
            return result.join('');
        },
        numAdd(targetEle, options) {
            options = options || {};
            let that = this;
            var $this = document.getElementById(targetEle),
                time = options.time, //总时间--毫秒为单位
                finalNum = options.finalNum, //要显示的真实数值
                regulator = options.regulator || 100, //调速器，改变regulator的数值可以调节数字改变的速度

                step = finalNum / (time / regulator),/*每30ms增加的数值--*/
                count = 0, //计数器
                initial = 0;

            var timer = setInterval(function () {
                count = count + step;
                if (count >= finalNum) {
                    clearInterval(timer);
                    count = finalNum;
                }
                //t未发生改变的话就直接返回
                //避免调用text函数，提高DOM性能
                var t = Math.floor(count);
                if (t == initial) return;

                initial = that.setNum(t);
                //that.setNum(initial)
                $this.innerHTML = initial;
            }, 30);
        },
        changeLang(e) {
            if (e === "en") {
                this.$router.push({ path: "/en/home"})
            } else {
                this.$router.push({ path: "/cn/home"})
            }
        },
        bannerAni() {
            let that = this;
            this.$anime({
                targets: '#bigTit',
                translateX: 0,
                complete: function(ani){
                    that.$anime({
                        targets: '#smallTit',
                        translateX: 0,
                        complete: function (ani) {
                            that.$anime({
                                targets: '.bannerCon .topDiv',
                                translateY: 10,
                                direction: 'normal',
                                delay: function (el, i, l) {
                                    return i * 100;
                                },
                                easing: 'easeOutExpo',
                            })
                        }
                    })
                }
            })
        },
        appAni() {
            let that = this;
            this.$anime({
                targets: '.app1',
                translateX: 0,
                translateY: 0,
                complete: function (ani) {
                    that.$anime({
                        targets: '.app2',
                        translateX: 0,
                        translateY: 0,
                        complete: function (ani) {
                            that.$anime({
                                targets: '.app3',
                                translateX: 0,
                                translateY: 0,
                                complete: function (ani) {
                                    that.$anime({
                                        targets: '.app4',
                                        translateX: 0,
                                        translateY: 0,
                                    })
                                }
                            })
                        }
                    })
                }
            })
        },
        funcAni() {
            let that = this;
            this.$anime({
                targets: '.func1',
                translateX: 0,
                translateY: 0,
                easing: 'linear',
                duration:1000,
                complete: function (ani) {
                    that.$anime({
                        targets: '.func2',
                        translateX: 0,
                        translateY: 0,
                        easing: 'linear',
                        duration: 1000,
                        complete: function (ani) {
                            that.$anime({
                                targets: '.func3',
                                translateX: 0,
                                translateY: 0,
                                easing: 'linear',
                                duration: 1000,
                                complete: function (ani) {
                                    that.$anime({
                                        targets: '.func4',
                                        translateX: 0,
                                        translateY: 0,
                                        easing: 'linear',
                                        duration: 1000,
                                    })
                                }
                            })
                        }
                    })
                }
            })
        },
        clentAni() {
            this.$anime({
                targets: '.clientList .oj',
                scale: [
                    //{ value: .1, easing: 'easeOutSine', duration: 500 },
                    { value: 1, easing: 'easeInOutQuad', duration: 1200 }
                ],
                delay: this.$anime.stagger(200, { grid: [6, 3], from: 'center' })
            })
        },
        isElem(el) {
            var clientHeight = window.innerHeight;
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            var offsetTop = el.offsetTop;
            var objHeight = el.offsetHeight;
            if (offsetTop < scrollTop + clientHeight && offsetTop + objHeight > scrollTop) {
                return true;
            } else {
                return false;
            }
        },
        mobAni() {
            let that = this;
            this.$anime({
                targets: '.mob1',
                translateX: 0,
                translateY: 0,
                easing: 'linear',
                duration: 1000,
                complete: function (ani) {
                    that.$anime({
                        targets: '.mob2',
                        translateX: 0,
                        translateY: 0,
                        easing: 'linear',
                        duration: 1000,
                        complete: function (ani) {
                            that.$anime({
                                targets: '.mob3',
                                translateX: 0,
                                translateY: 0,
                                easing: 'linear',
                                duration: 1000,
                                complete: function (ani) {
                                    that.$anime({
                                        targets: '.mob4',
                                        translateX: 0,
                                        translateY: 0,
                                        easing: 'linear',
                                        duration: 1000,
                                    })
                                }
                            })
                        }
                    })
                }
            })
        }
    }
};
</script>
