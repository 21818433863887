var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-container pull-height",
      nativeOn: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.handleLogin.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.needLogout,
                width: "30%",
                title: _vm.$t("Tips"),
                "append-to-body": "",
                "show-close": false,
                "close-on-click-modal": false,
                "close-on-press-escape": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.needLogout = $event
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("Exit the system, continue")))]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.goHome } }, [
                    _vm._v(_vm._s(_vm.$t("Cancel")))
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "danger" }, on: { click: _vm.goLogOut } },
                    [_vm._v(_vm._s(_vm.$t("Log Out")))]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }