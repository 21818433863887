<template>
    <div>
        <div class="nav">
            <div class="logo">
                <div class="logo1">
                    <a href="javascript:;">
                        <img src="/img/home/cnlogo1.png" />
                    </a>
                </div>
                <div class="logo2">
                    <a>
                        <img src="/img/home/cnlogo2.png" />
                    </a>
                </div>
            </div>
            <div class="menu">
                <div class="menuList" id="menuList">
                    <a href="javascript:;" class="act">Home</a>
                    <a href="javascript:;">Application Effect</a>
                    <a href="javascript:;">Technical Advantage</a>
                    <a href="javascript:;">Partner</a>
                    <a href="javascript:;">Login</a>
                </div>
                <div>
                    <el-select v-model="langVal" placeholder="Please enter" size="mini" class="lang" style="width:90px;" @change="changeLang">
                        <el-option v-for="item in lang"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div id="menuBtn" @click="nav">
                    <button>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="banner">
            <div class="bannerImg">
                <img src="/img/home/banner.png" />
            </div>
            <div class="bannerCon">
                <h2 id="bigTit">纺织行业数字化转型服务商</h2>
                <h4 id="smallTit">设备升级改造、SAAS软件、实施、维保、咨询、技术支持一条龙</h4>
                <div class="list">
                    <div>
                        <div>
                            <img src="/img/home/mesIcon.png" />
                        </div>
                        <h4>MES</h4>
                        <p>生产物联管理、明晰人员、设备、物料、能耗、产能、工艺、质量、排班、绩效等</p>
                    </div>
                    <div>
                        <div>
                            <img src="/img/home/jxcIcon.png" />
                        </div>
                        <h4>进销存</h4>
                        <p>原料采购、销售合同、出入库、配件采购和管理、产品追溯等</p>
                    </div>
                    <div>
                        <div>
                            <img src="/img/home/aiIcon.png" />
                        </div>
                        <h4>AI辅助</h4>
                        <p>生产物联管理、明晰人员、设备、物料、能耗、产能、工艺、质量、排班、绩效等</p>
                    </div>
                    <div>
                        <div>
                            <img src="/img/home/kpIcon.png" />
                        </div>
                        <h4>跨屏支持</h4>
                        <p>支持联屏看板、Pad、手机、嵌入式系统的显示及操作</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="num">
            <div>
                <div>
                    <h4>2000+</h4>
                    <p>工厂</p>
                </div>
                <div>
                    <h4>5000+</h4>
                    <p>设备</p>
                </div>
                <div>
                    <h4>2,000,000</h4>
                    <p>数据条数</p>
                </div>
                <div>
                    <h4>100m/day</h4>
                    <p>一天记录的数据</p>
                </div>
                <div>
                    <h4>500,000</h4>
                    <p>报警数</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            langVal:"en",
            lang: [
                {
                    value: 'cn',
                    label: '中文'
                },
                {
                    value: 'en',
                    label: 'English'
                }
            ]
        };
    },
    mounted() {
        window.onresize = function () {
            if (document.body.clientWidth >= 768) {
                document.querySelector("#menuList").style.cssText = "display:block";
            } else {
                document.querySelector("#menuList").style.cssText = "display:none";
            }
        }
        document.querySelector("#app").style.cssText = "height:auto;";
    },
    destroyed() {
        document.querySelector("#app").style.cssText = "height:100%;";
    },
    methods: {
        nav() {
            var h = window.getComputedStyle(document.querySelector("#menuList")).height;
            if (h === "0px") {
                document.querySelector("#menuList").style.cssText = 'height:auto;padding-bottom:20px;';
            } else {
                document.querySelector("#menuList").style.cssText = 'height:0;padding-bottom:0;';
            }
        },
        numAdd(targetEle, options) {
            options = options || {};

            var $this = document.getElementById(targetEle),
                time = options.time || $this.data('time'), //总时间--毫秒为单位
                finalNum = options.num || $this.data('value'), //要显示的真实数值
                regulator = options.regulator || 100, //调速器，改变regulator的数值可以调节数字改变的速度

                step = finalNum / (time / regulator),/*每30ms增加的数值--*/
                count = 0, //计数器
                initial = 0;

            var timer = setInterval(function () {

                count = count + step;

                if (count >= finalNum) {
                    clearInterval(timer);
                    count = finalNum;
                }
                //t未发生改变的话就直接返回
                //避免调用text函数，提高DOM性能
                var t = Math.floor(count);
                if (t == initial) return;

                initial = t;

                $this.innerHTML = initial;
            }, 30);
        },
        changeLang(e) {
            if (e === "en") {
                this.$router.push({ path: "/en/home" })
            } else {
                this.$router.push({ path: "/cn/home" })
            }
        }
    }
};
</script>
