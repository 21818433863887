var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "nav", attrs: { id: "nav" } }, [
      _vm._m(0),
      _c("div", { staticClass: "menu" }, [
        _vm._m(1),
        _c("div", { attrs: { id: "menuBtn" }, on: { click: _vm.nav } }, [
          _vm._m(2)
        ])
      ])
    ]),
    _vm._m(3),
    _vm._m(4),
    _vm._m(5),
    _vm._m(6),
    _vm._m(7),
    _vm._m(8),
    _vm._m(9)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("div", { staticClass: "logo1" }, [
        _c("a", { attrs: { href: "javascript:;" } }, [
          _c("img", { attrs: { src: "/img/home/cnlogo1.png" } })
        ])
      ]),
      _c("div", { staticClass: "logo2" }, [
        _c("a", [_c("img", { attrs: { src: "/img/home/cnlogo2.png" } })])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menuList", attrs: { id: "menuList" } }, [
      _c("a", { staticClass: "act", attrs: { href: "javascript:;" } }, [
        _vm._v("首页")
      ]),
      _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("应用效果")]),
      _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("技术优势")]),
      _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("合作伙伴")]),
      _c("a", { attrs: { href: "javascript:;" } }, [_vm._v("登录")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", [_c("span"), _c("span"), _c("span")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner", attrs: { id: "banner" } }, [
      _c("div", { staticClass: "bannerImg" }, [
        _c("img", { attrs: { src: "/img/home/banner.png" } })
      ]),
      _c("div", { staticClass: "bannerCon" }, [
        _c("h2", { attrs: { id: "bigTit" } }, [
          _vm._v("纺织行业数字化转型服务商")
        ]),
        _c("h4", { attrs: { id: "smallTit" } }, [
          _vm._v("设备升级改造、SAAS软件、实施、维保、咨询、技术支持一条龙")
        ]),
        _c("div", { staticClass: "list" }, [
          _c("div", { staticClass: "topDiv" }, [
            _c("div", [_c("img", { attrs: { src: "/img/home/mesIcon.png" } })]),
            _c("h4", [_vm._v("MES")]),
            _c("p", [
              _vm._v(
                "生产物联管理、明晰人员、设备、物料、能耗、产能、工艺、质量、排班、绩效等"
              )
            ])
          ]),
          _c("div", { staticClass: "topDiv" }, [
            _c("div", [_c("img", { attrs: { src: "/img/home/jxcIcon.png" } })]),
            _c("h4", [_vm._v("进销存")]),
            _c("p", [
              _vm._v("原料采购、销售合同、出入库、配件采购和管理、产品追溯等")
            ])
          ]),
          _c("div", { staticClass: "topDiv" }, [
            _c("div", [_c("img", { attrs: { src: "/img/home/aiIcon.png" } })]),
            _c("h4", [_vm._v("AI辅助")]),
            _c("p", [_vm._v("智能预警、BI分析、在线客服智能答疑，知识库管理")])
          ]),
          _c("div", { staticClass: "topDiv" }, [
            _c("div", [_c("img", { attrs: { src: "/img/home/kpIcon.png" } })]),
            _c("h4", [_vm._v("跨屏支持")]),
            _c("p", [_vm._v("支持联屏看板、Pad、手机、嵌入式系统的显示及操作")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "num", attrs: { id: "num" } }, [
      _c("div", [
        _c("div", [
          _c("h4", [
            _c("span", { attrs: { id: "gcnum" } }, [_vm._v("2000")]),
            _vm._v("+")
          ]),
          _c("p", [_vm._v("工厂")])
        ]),
        _c("div", [
          _c("h4", [
            _c("span", { attrs: { id: "sbnum" } }, [_vm._v("5000")]),
            _vm._v("+")
          ]),
          _c("p", [_vm._v("设备")])
        ]),
        _c("div", [
          _c("h4", { attrs: { id: "sjnum" } }, [_vm._v("2,000,000")]),
          _c("p", [_vm._v("数据条数")])
        ]),
        _c("div", [
          _c("h4", [
            _c("span", { attrs: { id: "ytnum" } }, [_vm._v("100")]),
            _vm._v("m/day")
          ]),
          _c("p", [_vm._v("一天记录的数据")])
        ]),
        _c("div", [
          _c("h4", { attrs: { id: "bjnum" } }, [_vm._v("500,000")]),
          _c("p", [_vm._v("报警数")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "moblist", attrs: { id: "moblist" } }, [
      _c("div", { staticClass: "mob1" }, [
        _c("img", { attrs: { src: "/img/home/mobicon1.png" } }),
        _c("div", [
          _c("h4", [_vm._v("MES")]),
          _c("p", [
            _vm._v(
              "生产物联管理、明晰人员、设备、物料、能耗、产能、工艺、质量、排班、绩效等"
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "mob2" }, [
        _c("img", { attrs: { src: "/img/home/mobicon2.png" } }),
        _c("div", [
          _c("h4", [_vm._v("进销存")]),
          _c("p", [
            _vm._v("原料采购、销售合同、出入库、配件采购和管理、产品追溯等")
          ])
        ])
      ]),
      _c("div", { staticClass: "mob3" }, [
        _c("img", { attrs: { src: "/img/home/mobicon3.png" } }),
        _c("div", [
          _c("h4", [_vm._v("AI辅助")]),
          _c("p", [_vm._v("智能预警、BI分析、在线客服智能答疑，知识库管理")])
        ])
      ]),
      _c("div", { staticClass: "mob4" }, [
        _c("img", { attrs: { src: "/img/home/mobicon4.png" } }),
        _c("div", [
          _c("h4", [_vm._v("跨屏支持")]),
          _c("p", [_vm._v("支持联屏看板、Pad、手机、嵌入式系统的显示及操作")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "application", attrs: { id: "application" } },
      [
        _c("div", { staticClass: "appTit" }, [
          _c("h4", [_vm._v("应用效果")]),
          _c("p", [_vm._v("APPLICATION")])
        ]),
        _c("div", { staticClass: "appList" }, [
          _c(
            "div",
            {
              staticClass: "app1",
              staticStyle: { transform: "translateX(-200%) translateY(-200%)" }
            },
            [
              _c("img", { attrs: { src: "/img/home/app1.png" } }),
              _c("div", [
                _c("div", [
                  _c("h5", [_vm._v("产能")]),
                  _c("p", [_vm._v("提升开台率、减少意外停机")])
                ])
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "app2",
              staticStyle: { transform: "translateX(200%) translateY(-200%)" }
            },
            [
              _c("img", { attrs: { src: "/img/home/app2.png" } }),
              _c("div", [
                _c("div", [
                  _c("h5", [_vm._v("效益")]),
                  _c("p", [_vm._v("降低工人数、提高单人产量")])
                ])
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "app3",
              staticStyle: { transform: "translateX(-200%) translateY(200%)" }
            },
            [
              _c("img", { attrs: { src: "/img/home/app3.png" } }),
              _c("div", [
                _c("div", [
                  _c("h5", [_vm._v("管理")]),
                  _c("p", [_vm._v("自动计件、质量监控")])
                ])
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "app4",
              staticStyle: { transform: "translateX(200%) translateY(200%)" }
            },
            [
              _c("img", { attrs: { src: "/img/home/app4.png" } }),
              _c("div", [
                _c("div", [
                  _c("h5", [_vm._v("追溯")]),
                  _c("p", [_vm._v("从原棉到成衣，全链条追溯")])
                ])
              ])
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "function", attrs: { id: "function" } }, [
      _c("div", { staticClass: "appTit" }, [
        _c("h4", [_vm._v("技术优势")]),
        _c("p", [_vm._v("FUNCTION")])
      ]),
      _c("div", { staticClass: "funcList" }, [
        _c(
          "div",
          {
            staticClass: "func1",
            staticStyle: { transform: "translateX(200%)" }
          },
          [
            _c("span", [_vm._v("1")]),
            _c("div", [
              _c("h5", [_vm._v("智能物联AIoT")]),
              _c("p", [_vm._v("软硬结合，优化边缘计算提升效果和效率")])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "func2",
            staticStyle: { transform: "translateX(200%)" }
          },
          [
            _c("span", [_vm._v("2")]),
            _c("div", [
              _c("h5", [_vm._v("实时大数据")]),
              _c("p", [_vm._v("秒级全车间单锭数据处理能力，瞬时通达")])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "func3",
            staticStyle: { transform: "translateX(200%)" }
          },
          [
            _c("span", [_vm._v("3")]),
            _c("div", [
              _c("h5", [_vm._v("全流程追溯")]),
              _c("p", [_vm._v("从原棉、纺纱、织染到成衣，全流程追溯")])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "func4",
            staticStyle: { transform: "translateX(200%)" }
          },
          [
            _c("span", [_vm._v("4")]),
            _c("div", [
              _c("h5", [_vm._v("开放平台")]),
              _c("p", [_vm._v("通过开放式工业级数据接口，与主流ERP系统对接")])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "client", attrs: { id: "client" } }, [
      _c("div", { staticClass: "appTit" }, [
        _c("h4", [_vm._v("合作伙伴")]),
        _c("p", [_vm._v("CLIENT")])
      ]),
      _c("div", { staticClass: "clientList" }, [
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        ),
        _c(
          "div",
          { staticClass: "oj", staticStyle: { transform: "scale(0)" } },
          [_vm._v("123")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer" }, [
      _c("div", [
        _vm._v(
          "\n            Copyright ® 2017-2021 General Intelligence All rights reserved."
        ),
        _c("br"),
        _vm._v(
          "\n            通用智能有限公司（香港）| 玑脉（上海）数据技术有限公司"
        ),
        _c("br"),
        _vm._v("\n            沪ICP备17047921号-2\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }